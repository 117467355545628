// Define initial states
const DEFAULT_PROVIDER_STATE = {
  connection: null,
  chainId: null,
  account: null,
  balance: null
};

const DEFAULT_TOKENS_STATE = {
  loaded: false,
  contracts: [],
  symbols: [],
  balances: []
};

const DEFAULT_EXCHANGE_STATE = {
  loaded: false,
  contract: {},
  transaction: {
    isSuccessful: false
  },
  transferInProgress: false,
  allOrders: {
    loaded: false,
    data: []
  },
  cancelledOrders: {
    data: []
  },
  filledOrders: {
    data: []
  },
  events: [],
  balances: []
};

// Provider Reducer
export const provider = (state = DEFAULT_PROVIDER_STATE, action) => {
  switch (action.type) {
    case 'PROVIDER_LOADED':
      return { ...state, connection: action.connection };
    case 'NETWORK_LOADED':
      return { ...state, chainId: action.chainId };
    case 'ACCOUNT_LOADED':
      return { ...state, account: action.account };
    case 'ETHER_BALANCE_LOADED':
      return { ...state, balance: action.balance };
    case 'ACCOUNT_DISCONNECTED':
      return { ...DEFAULT_PROVIDER_STATE };
    default:
      return state;
  }
};

// Token Reducer
export const tokens = (state = DEFAULT_TOKENS_STATE, action) => {
  switch (action.type) {
    case 'TOKEN_1_LOADED':
      return {
        ...state,
        loaded: true,
        contracts: [action.token],
        symbols: [action.symbol]
      };
    case 'TOKEN_1_BALANCE_LOADED':
      return {
        ...state,
        balances: [action.balance]
      };
    case 'TOKEN_2_LOADED':
      return {
        ...state,
        loaded: true,
        contracts: [...state.contracts, action.token],
        symbols: [...state.symbols, action.symbol]
      };
    case 'TOKEN_2_BALANCE_LOADED':
      return {
        ...state,
        balances: [...state.balances, action.balance]
      };
    case 'ACCOUNT_DISCONNECTED':
      return { ...DEFAULT_TOKENS_STATE };
    default:
      return state;
  }
};

// Exchange Reducer
export const exchange = (state = DEFAULT_EXCHANGE_STATE, action) => {
  let index, data;

  switch (action.type) {
    case 'EXCHANGE_LOADED':
      return { ...state, loaded: true, contract: action.exchange };
    case 'CANCELLED_ORDERS_LOADED':
      return { ...state, cancelledOrders: { loaded: true, data: action.cancelledOrders } };
    case 'FILLED_ORDERS_LOADED':
      return { ...state, filledOrders: { loaded: true, data: action.filledOrders } };
    case 'ALL_ORDERS_LOADED':
      return { ...state, allOrders: { loaded: true, data: action.allOrders } };
    case 'ORDER_CANCEL_REQUEST':
      return { ...state, transaction: { transactionType: 'Cancel', isPending: true, isSuccessful: false } };
    case 'ORDER_CANCEL_SUCCESS':
      return {
        ...state,
        transaction: { transactionType: 'Cancel', isPending: false, isSuccessful: true },
        cancelledOrders: {
          ...state.cancelledOrders,
          data: [...state.cancelledOrders.data, action.order]
        },
        events: [action.event, ...state.events]
      };
    case 'ORDER_CANCEL_FAIL':
      return { ...state, transaction: { transactionType: 'Cancel', isPending: false, isSuccessful: false, isError: true } };
    case 'ORDER_FILL_REQUEST':
      return { ...state, transaction: { transactionType: "Fill Order", isPending: true, isSuccessful: false } };
    case 'ORDER_FILL_SUCCESS':
      index = state.filledOrders.data.findIndex(order => order.id.toString() === action.order.id.toString());
      if (index === -1) {
        data = [...state.filledOrders.data, action.order];
      } else {
        data = state.filledOrders.data;
      }
      return {
        ...state,
        transaction: { transactionType: "Fill Order", isPending: false, isSuccessful: true },
        filledOrders: { ...state.filledOrders, data },
        events: [action.event, ...state.events]
      };
    case 'ORDER_FILL_FAIL':
      return { ...state, transaction: { transactionType: "Fill Order", isPending: false, isSuccessful: false, isError: true } };
    case 'EXCHANGE_TOKEN_1_BALANCE_LOADED':
      return { ...state, balances: [action.balance] };
    case 'EXCHANGE_TOKEN_2_BALANCE_LOADED':
      return { ...state, balances: [...state.balances, action.balance] };
    case 'TRANSFER_REQUEST':
      return { ...state, transferInProgress: true, transaction: { transactionType: 'Transfer', isPending: true, isSuccessful: false } };
    case 'TRANSFER_SUCCESS':
      return { ...state, transferInProgress: false, transaction: { transactionType: 'Transfer', isPending: false, isSuccessful: true }, events: [action.event, ...state.events] };
    case 'TRANSFER_FAIL':
      return { ...state, transferInProgress: false, transaction: { transactionType: 'Transfer', isPending: false, isSuccessful: false, isError: true } };
    case 'ACCOUNT_DISCONNECTED':
      return { ...DEFAULT_EXCHANGE_STATE };
    default:
      return state;
  }
};
