import React, { useState, useEffect } from 'react';
import './CircularNavbar.css';
import { useSelector, useDispatch } from 'react-redux';
import Blockies from 'react-blockies';

import logo from '../assets/TBar.svg';
import eth from '../assets/eth.svg';

import { loadAccount } from '../store/interactions';
import config from '../config.json';

/* Example Base Chain Icon */
const BaseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#0052FF"/>
    <path
      d="M12.0002 4.5L6.75024 7.5V13.5L12.0002 16.5L17.2502 13.5V7.5L12.0002 4.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4.5V10.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 13.5L12 10.5L6.75 13.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* NFT Icon */
const NftIcon = () => (
  <svg width="38" height="38" viewBox="0 0 590 590" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="590" height="590" rx="65" fill="#9b59b6" />
    <text
      x="50%"
      y="55%"
      dominantBaseline="middle"
      textAnchor="middle"
      fill="#fff"
      fontSize="180"
      fontFamily="Arial"
      fontWeight="bold"
    >
      NFT
    </text>
  </svg>
);

/* Trade Icon */
const TradeIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 5L26.7224 15H13.2776L20 5Z" fill="#3498db" />
    <path d="M20 35L13.2776 25H26.7224L20 35Z" fill="#2ecc71" />
    <path d="M5 20L15 13.2776V26.7224L5 20Z" fill="#e74c3c" />
    <path d="M35 20L25 26.7224V13.2776L35 20Z" fill="#f39c12" />
    <circle cx="20" cy="20" r="3" fill="#ecf0f1" />
  </svg>
);

/* Govern Icon */
const GovernIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 5L23.5 12H16.5L20 5Z" fill="#3498db" />
    <rect x="16" y="12" width="8" height="15" fill="#2ecc71" />
    <path d="M10 35H30M13 27H27M16 27V35M24 27V35" stroke="#34495e" strokeWidth="2" />
    <circle cx="20" cy="8" r="2" fill="#e74c3c" />
  </svg>
);

const CircularNavbar = () => {
  const provider = useSelector((state) => state.provider.connection);
  const chainId = useSelector((state) => state.provider.chainId);
  const account = useSelector((state) => state.provider.account);
  const balance = useSelector((state) => state.provider.balance);

  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);
  const [activeCircle, setActiveCircle] = useState(null);

  /* Connect Handler */
  const connectHandler = async () => {
    if (!provider) {
      console.log("No provider found.");
      return;
    }
    try {
      await loadAccount(provider, dispatch);
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  /* Disconnect Handler */
  const disconnectHandler = () => {
    dispatch({ type: 'ACCOUNT_DISCONNECTED' });
    dispatch({ type: 'BALANCE_LOADED', balance: '0' });
  };

  /* Switch Networks */
  const networkHandler = async (e) => {
    if (!provider) return;
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: e.target.value }],
      });
    } catch (error) {
      console.error("Error switching network:", error);
    }
  };

  /* Format Balance */
  const formatBalance = (rawBalance) => {
    const num = parseFloat(rawBalance);
    if (isNaN(num)) return '0';
    return num.toFixed(6).replace(/\.?0+$/, '');
  };

  /* Glow effect on circle nav items */
  const handleCircleClick = (id) => {
    setActiveCircle(id);
    setTimeout(() => setActiveCircle(null), 300);

    if (id === 'home') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (id === 'trade') {
      document.getElementById('exchange-anchor')?.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'govern') {
      document.getElementById('dao-anchor')?.scrollIntoView({ behavior: 'smooth' });
    } else if (id === 'nft') {
      window.open("https://opensea.io/collection/fmvm", '_blank');
    }
  };

  /* Scroll-based background transitions */
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  /* Decide which icon to show for network */
  const getNetworkIcon = () => {
    if (chainId === '0x1') {
      return <img src={eth} alt="ETH" className="network-icon" />;
    } else if (chainId === '0x2105') {
      return <BaseIcon />;
    }
    return null;
  };

 return (
    <>
      <div className={`navbar-row ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-content">
          <div className="navbar-tabs">
            <div className="circular-navbar-container">
              <div className={`circular-nav-item ${activeCircle === 'home' ? 'active' : ''}`} onClick={() => handleCircleClick('home')}>
                <div className="circular-nav-content logo-container">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <span className="nav-label">Home</span>
              </div>
              <div className={`circular-nav-item ${activeCircle === 'trade' ? 'active' : ''}`} onClick={() => handleCircleClick('trade')}>
                <div className="circular-nav-content">
                  <TradeIcon />
                </div>
                <span className="nav-label">Trade</span>
              </div>
              <div className={`circular-nav-item ${activeCircle === 'govern' ? 'active' : ''}`} onClick={() => handleCircleClick('govern')}>
                <div className="circular-nav-content">
                  <GovernIcon />
                </div>
                <span className="nav-label">Govern</span>
              </div>
              <div className={`circular-nav-item ${activeCircle === 'nft' ? 'active' : ''}`} onClick={() => handleCircleClick('nft')}>
                <div className="circular-nav-content">
                  <NftIcon />
                </div>
                <span className="nav-label">NFT</span>
              </div>
            </div>
          </div>
          <div className="navbar-right">
            <div className="tabs__account">
              <div className="network-selector">
                {getNetworkIcon()}
                {chainId && (
                  <select name="networks" id="networks" value={config[chainId] ? `0x${chainId.toString(16)}` : '0'} onChange={networkHandler}>
                    <option value="0" disabled>Select Network</option>
                    <option value="0x14a34">BaseSepolia</option>
                    <option value="0x2105">Base</option>
                  </select>
                )}
              </div>
              <button className="balance-button">
                <small>My Balance</small>
                <span className="balance-amount">
                  {balance ? formatBalance(balance) : '0'} ETH
                </span>
              </button>
              {account ? (
                <>
                  <a href={config[chainId] ? `${config[chainId].explorerURL}/address/${account}` : '#'} target="_blank" rel="noreferrer" className="account-link">
                    {account.slice(0, 5) + '...' + account.slice(38, 42)}
                    <Blockies seed={account} size={10} scale={3} color="#2187D0" bgColor="#F1F2F9" spotColor="#767F92" className="identicon" />
                  </a>
                  <button className="disconnect-btn" onClick={disconnectHandler}>
                    Disconnect
                  </button>
                </>
              ) : (
                <button className="connect-btn" onClick={connectHandler}>
                  Connect
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="search-row">
        <input
          type="text"
          className="navbar-search"
           placeholder="Search for tokens, NFTs, or addresses..."
          onChange={(e) => {
            const query = e.target.value;
            console.log("Searched for:", query);
          }}
          onKeyPress={(e) => {
           if (e.key === 'Enter') {
            // Perform search action
            console.log("Performing search for:", e.target.value);
          }
         }} 
        />
      </div>
    </>
  );
};

export default CircularNavbar;
