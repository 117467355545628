import React, { useState } from 'react';
import { FaLock } from 'react-icons/fa';
import './DAOPage.css';

const DAOPage = () => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  return (
    <div className="dao-page">
      {/* Updated Engaging Header */}
      <h1 className="dao-heading">ToughBar DAO: <span className="dao-subtitle">Steer the DeFi Revolution</span></h1>

      <section className="dao-overview">
        <h2>DAO Overview</h2>
        <p>ToughBar DAO governs the ToughBar ecosystem—including our DeFi exchange, NFT collection, and Spades game—by empowering token holders to shape its direction.</p>
      </section>

      <section className="token-utility">
        <h2>Token Utility</h2>
        <p>Our 1 billion BTBAR tokens power governance and provide utility across our ecosystem:</p>
        <ul>
          <li>Governance voting rights</li>
          <li>Staking for enhanced benefits</li>
          <li>Trading pairs on our DeFi exchange</li>
        </ul>
      </section>

      <section className="governance-process">
        <h2>Governance Process</h2>
        <ol>
          <li>Proposal Creation: Any BTBAR holder can create a proposal</li>
          <li>Discussion Period: 3 days for community debate</li>
          <li>Voting Period: 4 days for token-weighted voting</li>
          <li>Execution: Passed proposals are implemented by the dev team</li>
        </ol>
      </section>

      <section className="nft-integration">
        <h2>NFT Integration</h2>
        <p>Our 10,000 ToughBar NFTs provide unique benefits:</p>
        <ul>
          <li>Voting power boost in DAO decisions</li>
          <li>Exclusive access to certain proposals</li>
          <li>Special roles in our Spades game</li>
        </ul>
      </section>

      <section className="defi-exchange">
        <h2>DeFi Exchange Integration</h2>
        <p>The DAO governs key aspects of our exchange:</p>
        <ul>
          <li>Fee structures</li>
          <li>Listing new trading pairs</li>
          <li>Liquidity mining programs</li>
        </ul>
      </section>

      <section className="spades-game">
        <h2>Spades Game Connection</h2>
        <p>DAO members influence the development of our Spades game:</p>
        <ul>
          <li>Voting on new features</li>
          <li>Determining tournament structures</li>
          <li>Allocating prizes from game revenues</li>
        </ul>
      </section>

      <section className="join-dao">
        <h2>Join the DAO</h2>
        <p>To participate in ToughBar DAO:</p>
        <ol>
          <li>Acquire BTBAR tokens from our DeFi exchange</li>
          <li>Stake your tokens for voting power</li>
          <li>Join our community forums to discuss proposals</li>
          <li>Cast your votes on active proposals</li>
        </ol>
        <button 
          className={`join-button ${!isWalletConnected ? 'locked' : ''}`}
          onClick={() => setIsWalletConnected(!isWalletConnected)}
        >
          {!isWalletConnected && <FaLock className="lock-icon" />}
          {isWalletConnected ? 'Join DAO' : 'Connect Wallet to Join'}
        </button>
      </section>
    </div>
  );
};

export default DAOPage;
