import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import config from '../config.json';
import './ExchangeHeader.css';


import {
  loadProvider,
  loadNetwork,
  loadAccount,
  loadTokens,
  loadExchange,
  loadAllOrders,
  subscribeToEvents
} from '../store/interactions';

import Navbar from './CircularNavbar'
import HeroSection from './HeroSection';
import ExchangeHeader from './ExchangeHeader';
import Markets from './Markets'
import Balance from './Balance'
import Order from './Order'
import PriceChart from './PriceChart'
import Transactions from './Transactions'
import Trades from './Trades'
import OrderBook from './OrderBook'
import Alert from './Alert'
import Footer from './Footer';
import DAOPage from './DAOPage';

function ExchangeContent() {
  return (
   <>
    <ExchangeHeader /> 
    <main className='exchange grid'>
      <section className='exchange__section--left grid'>
        <Markets />
        <Balance />
        <Order />
      </section>
      <section className='exchange__section--right grid'>
        <PriceChart />
        <Transactions />
        <Trades />
        <OrderBook />
      </section>
    </main>
   </>
  );
}

function App() {
  const dispatch = useDispatch()

  const loadBlockchainData = useCallback(async () => {
    // Connect Ethers to blockchain
    const provider = loadProvider(dispatch)

    // Fetch current network's chainId (e.g. hardhat: 31337, kovan: 42)
    const chainId = await loadNetwork(provider, dispatch)

    // Reload page when network changes
    window.ethereum.on('chainChanged', () => {
      window.location.reload()
    })

    // Fetch current account & balance from Metamask when changed
    window.ethereum.on('accountsChanged', () => {
      loadAccount(provider, dispatch)
    })

    // Load token smart contracts
    const BTbar = config[chainId].BTbar
    const WETH = config[chainId].WETH
    await loadTokens(provider, [BTbar.address, WETH.address], dispatch)

    // Load exchange smart contract
    const exchangeConfig = config[chainId].exchange
    const exchange = await loadExchange(provider, exchangeConfig.address, dispatch)

    // Fetch all orders: open, filled, cancelled
    loadAllOrders(provider, exchange, dispatch)

    // Listen to events
    subscribeToEvents(exchange, dispatch)
  }, [dispatch])

  useEffect(() => {
    loadBlockchainData()
  }, [loadBlockchainData])

 return (
    <div id="top">
      <Navbar />
      <div className="content-background">
        <div className="content-divider"></div>
        <HeroSection />
        <div id="exchange-anchor" style={{position: 'relative', top: '-80px'}}></div>
        <div id="exchange">
          <ExchangeContent />
        </div>
        <div id="dao-anchor" style={{position: 'relative', top: '-80px'}}></div>
        <div id="dao">
          <DAOPage />
        </div>
      </div>
      <Alert />
      <Footer />
    </div>
  );
}

export default App;
