import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';
import sampleSVG from '../assets/TBar.svg';
import placeholderSVG from '../assets/TBar.svg';
import CircularFeatureContainer from './CircularFeatureContainer';
import merchPlaceholder from '../assets/TBar.svg';
import FeatureContainer from './FeatureContainer';
import daoPlaceholder from '../assets/TBar.svg';
import spadesPlaceholder from '../assets/TBar.svg';

import placeholder1 from '../assets/camo_hoody.png';
import placeholder2 from '../assets/Grey_Hoody.png';
import placeholder3 from '../assets/Knight 2.png';
import placeholder4 from '../assets/Ninja.png';
import placeholder5 from '../assets/camo_hoody.png';

import { ReactComponent as LockIcon } from '../assets/lock-icon.svg';

const PlaceholderSVG = () => (
  <img src={placeholderSVG} alt="Loading..." width="300" height="300" />
);

const HeroSection = () => {
  const [counter, setCounter] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isPlaceholderAnimating, setIsPlaceholderAnimating] = useState(false);

  // Link to OpenSea NFT
  const openSeaCollectionUrl = "https://opensea.io/collection/fmvm";

  // A pre-filled Uniswap V2 link with BTBAR as the output token (chain=base)
  const btbarUniswapUrl = "https://app.uniswap.org/#/swap?chain=base&use=V2&outputCurrency=0x8BB8eF72A185ef8850B9713cE74D485C86A8e4Df";

  // DexScreener embed link for your token. Adjust the link to match your DexScreener pair. 
  // Add "?embed=1" or "embed=1" to the end for an embeddable chart
  const dexScreenerUrl = "https://dexscreener.com/base/0x8BB8eF72A185ef8850B9713cE74D485C86A8e4Df?embed=1";

  // Contract addresses
  const btbarAddress = "0x8BB8eF72A185ef8850B9713cE74D485C86A8e4Df";
  const exchangeAddress = "0x5b2D7F1E58D971bf4173C21cE99a403695F60e0d";
  const governorAddress = "0x918d036eaeb6e63e11701CFF27d0eD0b8Afa2481";

  // Base explorer link
  const baseExplorerUrl = "https://basescan.org/address/";

  // Preloaded placeholders
  const placeholderImages = useMemo(() => [
    placeholder1,
    placeholder2,
    placeholder3,
    placeholder4,
    placeholder5
  ], []);

  // Basic rotation logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter((prevCounter) => (prevCounter % 2000) + 1);
      setIsLoading(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, [counter]);

  // Rotating placeholders
  useEffect(() => {
    if (isLoading) {
      setIsPlaceholderAnimating(true);
      const placeholderTimer = setInterval(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholderImages.length);
      }, 500);

      return () => {
        clearInterval(placeholderTimer);
        setIsPlaceholderAnimating(false);
      };
    }
  }, [isLoading, placeholderImages.length]);

  // Preload images
  useEffect(() => {
    placeholderImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, [placeholderImages]);

  const ipfsImageUrl = `https://ipfs.io/ipfs/QmeDJCtzS5NM61CKQ2RqtpHhcUhZ8gBJ3EhTriSCTifsdb/${counter}.png`;

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1 className="hero-title">
          Base ToughBar Hub:
          <br />
          <span className="hero-subtitle">Unleash Your Inner DeFi Adventurer</span>
        </h1>

        <img src={sampleSVG} alt="Hero" className="hero-svg" />

        <p className="hero-description">
          Step into a pop-up DeFi meme universe—sometimes it’s a rug-pull, sometimes it’s the next big thing. 
          Here, your creativity knows no bounds as you chase tomorrow’s trends with unstoppable meme energy.
        </p>

        {/* BUY + ADDRESSES container */}
        <div className="buy-and-addresses-container">
          <button
            className="cta-button buy-now-button"
            onClick={() => window.open(btbarUniswapUrl, "_blank")}
          >
            Buy BTBAR Now
          </button>

          <div className="hero-contract-links">
            <p>
              <strong>BTBAR Token:</strong>{" "}
              <a
                href={`${baseExplorerUrl}${btbarAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {btbarAddress}
              </a>
            </p>
            <p>
              <strong>Exchange:</strong>{" "}
              <a
                href={`${baseExplorerUrl}${exchangeAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {exchangeAddress}
              </a>
            </p>
            <p>
              <strong>Governor:</strong>{" "}
              <a
                href={`${baseExplorerUrl}${governorAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {governorAddress}
              </a>
            </p>
          </div>
        </div>

        {/* Side-by-side container for Uniswap + DexScreener */}
        <div className="swap-and-chart">
          {/* Uniswap embed */}
          <div className="uniswap-embed">
            <h3>Quick Swap</h3>
            <iframe
              title="UniswapV2Embed"
              src={btbarUniswapUrl}
              height="600px"
              width="100%"
              style={{ border: "none", maxWidth: "450px" }}
            />
          </div>

          {/* DexScreener embed */}
          <div className="dexscreener-embed">
            <h3>Live DexScreener Chart</h3>
            <iframe
              title="DexScreenerChart"
              src={dexScreenerUrl}
              height="600px"
              width="100%"
              style={{ border: "none", maxWidth: "450px" }}
            />
          </div>
        </div>

        <div className="cta-container">
          <a
            href="#events"
            className="cta-button locked"
            aria-disabled="true"
            role="button"
            onClick={(e) => e.preventDefault()}
          >
            <LockIcon className="lock-icon" />
            ToughBar Lower Layer Events
          </a>
          <a
            href={openSeaCollectionUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="cta-button opensea-link"
          >
            Onchain Badges
          </a>
        </div>
      </div>

      <a
        href={openSeaCollectionUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="opensea-link"
      >
        Embark on Your NFT Odyssey
      </a>

      <div className="ipfs-section">
        <h2 className="section-title">Pixel-Perfect Decentralized Masterpiece</h2>
        {isLoading && (
          <img
            src={placeholderImages[currentPlaceholderIndex]}
            alt="Loading Placeholder"
            className={`placeholder-image ${isPlaceholderAnimating ? 'fade-in-out' : ''}`}
          />
        )}
        {!isLoading && (
          <img
            src={ipfsImageUrl}
            alt={`Decentralized Asset #${counter}`}
            className="ipfs-image"
            onLoad={() => {
              console.log(`Image ${counter}.png loaded successfully.`);
              setIsLoading(false);
            }}
            onError={(e) => {
              console.log(`Failed to load image ${counter}.png. Redirecting to OpenSea.`);
              e.target.onerror = null;
              e.target.src = openSeaCollectionUrl;
              setIsLoading(false);
            }}
          />
        )}
        <p className="ipfs-description">
          Witness the birth of multi-chain Agents, one pixel at a time.
        </p>
      </div>

      <div className="event-highlights">
        <h2 className="section-title">Blockchain Bonanzas on the Horizon</h2>
        <ul className="event-list">
          <li>DeFi Summit 2025 - The Big Apple's Blockchain Extravaganza</li>
          <li>Blockchain Innovators Webinar Series - Mind-Bending Bytes of Wisdom</li>
          <li>ToughBar Annual Conference - Tokyo's Techno-Crypto Spectacular</li>
        </ul>
      </div>

      <CircularFeatureContainer
        title="ToughBar DAO"
        description="Join our decentralized autonomous organization and help shape the future of ToughBar. 
                    Participate in governance, propose ideas, and vote on key decisions."
        imageSrc={daoPlaceholder}
        link="/dao"
        linkText="Join DAO"
        isLocked={true}
      />

      <div className="features-container">
        <FeatureContainer
          title="ToughBar Spades Game"
          description="Get ready to dive into the ToughBar Spades Game! An exciting blockchain-based card game 
                       that blends strategy, luck, and decentralized fun. Elevate your gaming experience soon."
          imageSrc={spadesPlaceholder}
          link="#spades-game"
          linkText="Learn More"
          isLocked={true}
        />

        <FeatureContainer
          title="ToughBar Merch"
          description="Show off your ToughBar flair with exclusive merchandise! From stylish apparel to unique 
                       accessories, gear up with products designed for blockchain enthusiasts."
          imageSrc={merchPlaceholder}
          link="#merch"
          linkText="Shop Now"
          isLocked={true}
        />
      </div>
    </section>
  );
};

export default HeroSection;
