import React from 'react';
import './CircularFeatureContainer.css';
import lockIcon from '../assets/lock-icon.svg';

const CircularFeatureContainer = ({ title, description, imageSrc, link, linkText, isLocked = false }) => {
  return (
    <div className={`circular-feature-container ${isLocked ? 'locked' : ''}`}>
      <div className="circular-content">
        <img src={imageSrc} alt={`${title} Illustration`} className="circular-feature-image" />
        <h2 className="circular-feature-title">{title}</h2>
        <p className="circular-feature-description">{description}</p>
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer" className="circular-feature-button" onClick={e => isLocked && e.preventDefault()}>
            {isLocked ? (
              <>
                <img src={lockIcon} alt="Locked" className="lock-icon" />
                Coming Soon
              </>
            ) : linkText}
          </a>
        )}
      </div>
      {isLocked && <div className="circular-locked-overlay"></div>}
    </div>
  );
};

export default CircularFeatureContainer;