import React from 'react';
import './FeatureContainer.css';
import lockIcon from '../assets/lock-icon.svg'; // Make sure to add this SVG to your assets

const FeatureContainer = ({ title, description, imageSrc, link, linkText, isLocked = false }) => {
  return (
    <div className={`feature-container ${isLocked ? 'locked' : ''}`}>
      <img src={imageSrc} alt={`${title} Illustration`} className="feature-image" />
      <h2 className="feature-title">{title}</h2>
      <p className="feature-description">{description}</p>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer" className="feature-button" onClick={e => isLocked && e.preventDefault()}>
          {isLocked ? (
            <>
              <img src={lockIcon} alt="Locked" className="lock-icon" />
              Coming Soon
            </>
          ) : linkText}
        </a>
      )}
      {isLocked && <div className="locked-overlay"></div>}
    </div>
  );
};

export default FeatureContainer;
