import { useSelector, useDispatch } from 'react-redux';
import Chart from 'react-apexcharts';

// Example: If you create actions to fetch new data
// import { fetchPriceData } from '../store/actions';

import arrowDown from '../assets/down-arrow.svg';
import arrowUp from '../assets/up-arrow.svg';

import {
  options as defaultOptions,
  defaultSeries,
  series as demoSeries
} from './PriceChart.config';

import { priceChartSelector } from '../store/selectors';

import Banner from './Banner';
import { useState } from 'react';

const PriceChart = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.provider.account);
  const symbols = useSelector((state) => state.tokens.symbols);
  const priceChart = useSelector(priceChartSelector);

  // Local UI states
  const [chartType, setChartType] = useState('candlestick');
  const [timeframe, setTimeframe] = useState('1H'); // e.g. '1H', '4H', '1D', etc.

  // Copy the default options, so we can override chartType or other settings:
  const chartOptions = {
    ...defaultOptions,
    chart: {
      ...defaultOptions.chart,
      type: chartType
    }
  };

  // You can dispatch an action to fetch new data based on the timeframe:
  const handleTimeframeChange = (tf) => {
    setTimeframe(tf);
    // dispatch(fetchPriceData(tf));
  };

  // Example advanced usage: if your Redux store doesn't have real data yet,
  // we use `demoSeries` as a fallback.
  const chartSeries = priceChart?.series || demoSeries || defaultSeries;

  return (
    <div className="component exchange__chart">
      <div className="component__header flex-between">
        <div className="flex">
          <h2>{symbols && `${symbols[0]}/${symbols[1]}`}</h2>

          {priceChart && (
            <div className="flex">
              {priceChart.lastPriceChange === '+' ? (
                <img src={arrowUp} alt="Arrow up" />
              ) : (
                <img src={arrowDown} alt="Arrow down" />
              )}
              <span
                className={
                  priceChart.lastPriceChange === '+' ? 'up' : 'down'
                }
              >
                {priceChart.lastPrice}
              </span>
            </div>
          )}
        </div>

        {/* Timeframe Buttons */}
        <div className="chart-controls">
          {['1H', '4H', '1D', '1W', '1M'].map((tf) => (
            <button
              key={tf}
              className={`timeframe-btn ${tf === timeframe ? 'active' : ''}`}
              onClick={() => handleTimeframeChange(tf)}
            >
              {tf}
            </button>
          ))}

          {/* Chart Type Toggle */}
          <button
            className={`chart-type-btn ${chartType === 'candlestick' ? 'active' : ''}`}
            onClick={() => setChartType('candlestick')}
          >
            Candlestick
          </button>
          <button
            className={`chart-type-btn ${chartType === 'line' ? 'active' : ''}`}
            onClick={() => setChartType('line')}
          >
            Line
          </button>
        </div>
      </div>

      {!account ? (
        <Banner text={'Please connect with Metamask'} />
      ) : (
        <Chart
          type={chartType}
          options={chartOptions}
          series={chartSeries}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default PriceChart;
