export const options = {
  chart: {
    type: 'candlestick',            // default, but can be changed dynamically
    height: '100%',
    width: '100%',
    background: '#1E1E1E',          // Dark background
    foreColor: '#CCD6F6',           // Text color
    toolbar: {
      show: true,                   // Enable advanced toolbar
      tools: {
        download: true,            // Let users download PNG/SVG
        selection: true,           // Selection/brush for zooming
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true
      },
      autoSelected: 'zoom'
    },
    animations: {
      enabled: true,
      speed: 800
    },
    zoom: {
      enabled: true
    }
  },
  // Crosshairs and advanced tooltips
  tooltip: {
    enabled: true,
    shared: true,
    intersect: false,
    theme: 'dark',                   // 'light' or 'dark'
    x: {
      show: true,
      format: 'dd MMM HH:mm'         // More descriptive date/time format
    },
    y: {
      formatter: (value) => {
        // Example: Format as a fixed 2 decimal place currency
        return `$${value.toFixed(2)}`;
      },
      title: {
        formatter: () => 'Price: '
      }
    }
  },
  // Make the grid more subtle
  grid: {
    show: true,
    borderColor: '#4A4A4A',
    strokeDashArray: 1
  },
  plotOptions: {
    candlestick: {
      colors: {
        upward: '#25CE8F',          // Green
        downward: '#F45353'         // Red
      },
      wick: {
        useFillColor: true
      }
    }
  },
  // X-axis as datetime
  xaxis: {
    type: 'datetime',
    labels: {
      style: {
        colors: '#C9CBCF',
        fontSize: '12px'
      }
    },
    // If you want a custom date formatter, you can do something like:
    // labels: {
    //   datetimeFormatter: {
    //     day: 'MMM dd'
    //   }
    // },
    tooltip: {
      enabled: false   // We'll rely on the shared tooltip above
    }
  },
  // Primary Y-Axis (for Price)
  yaxis: [
    {
      seriesName: 'Price',
      labels: {
        style: {
          colors: '#C9CBCF',
          fontSize: '12px'
        },
        formatter: (value) => {
          return `$${value.toFixed(2)}`;
        }
      },
      tooltip: {
        enabled: true
      }
    },
    // If you want to add Volume bars, you can add a second y-axis:
    // {
    //   seriesName: 'Volume',
    //   opposite: true,
    //   labels: {
    //     style: {
    //       colors: '#C9CBCF',
    //       fontSize: '12px'
    //     },
    //     formatter: (value) => {
    //       // e.g., show in thousands or millions
    //       if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M';
    //       if (value >= 1000) return (value / 1000).toFixed(1) + 'k';
    //       return value;
    //     }
    //   }
    // }
  ]
};

export const defaultSeries = [];

/**
 * Example candlestick series for demonstration:
 * Each data entry: [timestamp, [open, high, low, close]]
 */
export const series = [
  {
    name: 'Price',
    data: [
      [1683004800000, [6600, 6620, 6582, 6615]], // Example data
      [1683012000000, [6615, 6630, 6600, 6628]],
      [1683019200000, [6628, 6680, 6620, 6645]],
      [1683026400000, [6645, 6650, 6610, 6622]],
      // ...
    ]
  },
  // If you want a volume series, you can add something like:
  // {
  //   name: 'Volume',
  //   type: 'bar',
  //   data: [
  //     [1683004800000, 5000],
  //     [1683012000000, 3200],
  //     [1683019200000, 7800],
  //     [1683026400000, 4400],
  //     // ...
  //   ]
  // }
];
