import React from 'react';
import './ExchangeHeader.css';

const ExchangeHeader = () => {
  return (
    <div className="exchange-header">
      <div className="exchange-header-content">
        <h1 className="exchange-title">
          <span className="title-word">ToughBar</span>
          <span className="title-word">DeFi</span>
          <span className="title-word">Exchange</span>
        </h1>
        <div className="exchange-subtitle">Empowering Your Crypto Journey</div>
        <div className="exchange-decoration">
          <div className="decoration-circle"></div>
          <div className="decoration-line"></div>
          <div className="decoration-circle"></div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeHeader;
